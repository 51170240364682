import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { create, cssomSheet } from "twind";

// 1. Create separate CSSStyleSheet
const sheet = cssomSheet({ target: new CSSStyleSheet() });

// 2. Use that to create an own twind instance
const { tw } = create({ sheet });

@customElement("x-dropdown")
export class Dropdown extends LitElement {
  // 3. Apply the same style to each instance of this component
  static styles = [sheet.target];

  @property()
  label?: string;

  attributeChangedCallback(name: string, _old: string, value: string): void {
    // console.log(name);
  }

  private _onItemClicked(e) {
    e.stopPropagation();
    const event = new CustomEvent("dropdown-item-selected", {
      bubbles: true,
      detail: e.detail,
    });
    this.dispatchEvent(event);
  }

  // Render the UI as a function of component state
  render() {
    return html`
      <button class="${tw`bg-purple-400 flex items-center justify-center`}">
        ${this.label}
      </button>
      <div>
        <slot @item-clicked=${this._onItemClicked}></slot>
      </div>
    `;
  }
}

@customElement("x-dropdown-item")
export class DropdownItem extends LitElement {
  // 3. Apply the same style to each instance of this component
  static styles = [sheet.target];

  // Declare reactive properties
  @property()
  label?: string;

  @property()
  description?: string;

  private _clickHandler(e) {
    const event = new CustomEvent("item-clicked", {
      bubbles: true,
      detail: { id: this.id },
    });
    this.dispatchEvent(event);
    // this.onItemClicked(e);
  }

  // Render the UI as a function of component state
  render() {
    return html`<div
      class="${tw`cursor-pointer text-red-500`}"
      @click="${this._clickHandler}"
    >
      <div>${this.label}</div>
      <div>${this.description}</div>
    </div>`;
  }
}
